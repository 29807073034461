<template>
    <div class="contact-us">
        <div class="container">
            <div class="form-container">
                <div class="header-contactUs">
                    <h2 class="context-title">Contact us</h2>
                    <div class="line-yellow" />
                </div>
                <b-form @submit="onSubmit" v-if="show" class="form-border">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group class="add-style" id="input-group-1">
                                <label :class="isSubmitted && !$v.form.firstName.firstNameRegex && 'label-validation-color'" for="input-1">First Name</label>
                                <b-form-input
                                    id="input-1"
                                    v-model="form.firstName"
                                    class="rounded"
                                    :class="isSubmitted && !$v.form.firstName.firstNameRegex ? 'input-bgLight field-validation-border' : 'input-bgLight'"
                                ></b-form-input>
                                <span class="field-validation-message" v-if="isSubmitted && !$v.form.firstName.firstNameRegex">First Name is invalid</span>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group id="input-group-2">
                                <label :class="isSubmitted && !$v.form.lastName.lastNameRegex && 'label-validation-color'" for="input-2">Last Name</label>
                                <b-form-input
                                    id="input-2"
                                    v-model="form.lastName"
                                    class="rounded"
                                    :class="isSubmitted && !$v.form.lastName.lastNameRegex ? 'input-bgLight field-validation-border' : 'input-bgLight'"
                                ></b-form-input>
                                <span class="field-validation-message" v-if="isSubmitted && !$v.form.lastName.lastNameRegex">Last Name is invalid</span>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-form-group id="input-group-3">
                        <label :class="isSubmitted && (!$v.form.email.required || !$v.form.email.email) && 'label-validation-color'" for="input-3">Email *</label>
                        <b-form-input
                            id="input-3"
                            v-model="form.email"
                            class="rounded"
                            :class="isSubmitted && (!$v.form.email.required || !$v.form.email.email) ? 'input-bgLight field-validation-border' : 'input-bgLight'"
                        ></b-form-input>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.email.required">Email is required</span>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.email.email">Email is invalid</span>
                    </b-form-group>
                    <b-form-group id="input-group-4">
                        <label :class="isSubmitted && (!$v.form.subject.required || !$v.form.subject.subjectRegex) && 'label-validation-color'" for="input-4">Subject *</label>
                        <b-form-input
                            id="input-4"
                            v-model="form.subject"
                            class="rounded"
                            :class="isSubmitted && (!$v.form.subject.required || !$v.form.subject.subjectRegex) ? 'input-bgLight field-validation-border' : 'input-bgLight'"
                        ></b-form-input>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.subject.required">Subject is required</span>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.subject.subjectRegex">Subject is invalid</span>
                    </b-form-group>
                    <!--Bootstrap-Vue only has the double arrow carets as options for their select/dropdowns. Need to add a custom caret via CSS-->
                    <b-form-group id="input-group-5">
                        <b-row>
                            <b-col cols="6">
                                <label for="input-5">Division</label>
                                <b-form-select
                                    id="input-5"
                                    
                                    v-model="form.division"
                                    :options="divisions"
                                    class="border-dev input-bgLight rounded custom-select-caret"
                                >
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group id="input-group-6">
                        <label :class="isSubmitted && (!$v.form.message.required || !$v.form.message.messageRegex) && 'label-validation-color'" for="input-6">Message *</label>
                        <b-form-textarea
                            id="input-6"
                            v-model="form.message"
                            class="rounded"
                            :class="isSubmitted && (!$v.form.message.required || !$v.form.message.messageRegex) ? 'input-bgLight field-validation-border' : 'input-bgLight'"
                        ></b-form-textarea>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.message.required">Message is required</span>
                        <span class="field-validation-message" v-if="isSubmitted && !$v.form.message.messageRegex">Message is invalid</span>
                    </b-form-group>

                    <button class="custom-button" type="submit" variant="primary">
                        Submit
                    </button>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, helpers } from "vuelidate/lib/validators";
import { contactDivisions, addContact } from "../../../services/contactUs";

const firstNameRegex = helpers.regex('firstNameRegex', /^[a-zA-Z]*$/);
const lastNameRegex = helpers.regex('lastNameRegex', /^[a-zA-Z]*$/);
const subjectRegex = helpers.regex('subjectRegex',  /^[a-zA-Z0-9\s]+$/);
const messageRegex = helpers.regex('messageRegex', /^[a-zA-Z0-9.,:;%&@!$#()'"?\s]+$/);

export default {
    data() {
        return {
            form: {
                email: "",
                firstName: "",
                lastName: "",
                subject: "",
                division: null,
                message: "",
            },
            divisions: [],
            show: true,
            isSubmitted: false,
        };
    },
    validations: {
        form: {
            firstName: {
                firstNameRegex,
            },
            lastName: {
                lastNameRegex,
            },
            email: {
                required,
                email,
            },
            subject: {
                required,
                subjectRegex,
            },
            message: {
                required,
                messageRegex,
            }
        }
    },
    methods: {
        async onSubmit(event) {
            this.$v.$touch();
            this.isSubmitted = true;

            event.preventDefault();

            if(!this.$v.$invalid) {
                const { id } = await addContact(this.form);

                if(id) {
                    this.$toast("The contact was submitted successfully.", {
                        type: 'success',
                    });
                    this.resetForm();
                }
                else {
                    this.$toast("The contact failed to be submitted.", {
                        type: 'error',
                    });
                }

                this.isSubmitted = false;
            }
        },
        resetForm(){
            this.form.email = "";
            this.form.firstName = "";
            this.form.lastName = "";
            this.form.subject = "",
            this.form.message = "",
            this.form.division = null;
        }
    },
    mounted() {
        this.divisions = contactDivisions();
    }
};
</script>
<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.contact-us {
	background-color: #fff;
	font-family: 'Inter', sans-serif !important;
	font-weight: 400; 
	color: #040505;
}

.header-contactUs {
    max-width: 100%;
    align-items: center;
    padding-bottom: 2.5rem;
}

.container {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 3vh;
    padding-bottom: 5vh;
    opacity: 1;
    font-family: "Roboto";
}

.form-container {
    max-width: 100%;
    width: 100%;
}

.border-dev {
    border-color: #040505;
    font-size: 1.3rem;
}

#input-group-6 .form-control {
    border-color: #040505;
}

label {
    color: #040505;
    font-weight: normal;
    font-size: 1.3rem;
}

.input-bgLight {
    background-color: #ffffff;
    color: #040505;
}

.input-bgLight:focus {
    background-color: #ffffff;
    color: #040505;
}

.context-title {
    padding-top: 10vh;
}

.label-validation-color {
    color: #ff0000;
}

.field-validation-border {
    border-color: #ff0000 !important;
    border-width: 2px;
}

.field-validation-message {
    color: #ff0000;
    font-size: large;
}

//replace default double-arrow select icon from bootstrap-vue with a custom single down-arrow icon
.custom-select-caret {
    background-image: url("./../../../assets/svgs/caret.svg");
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-position: right 10px center;
  }

@media (max-width: 768px) {
    .form-container {
        max-width: 95vw;
        width: 100%;
    }
}
</style>